<template>
  <!--药品查询内容浏览记录-->
  <div style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
      <div class="DrugCont-top">
        <div class="top_cont">
          <div class="DrugCont-top-input">
            <span>操作日期：</span>
            <a-range-picker style="margin-right: 15px" @change="onChange" />
            <a-button type="primary" @click="searchBtn">
              查询
            </a-button>
          </div>
          <div class="DrugCont-top-input">
            <a-button  @click="deriveBtn" >
              <a-icon style="color: #00A854;" type="file-excel"/>
              批量导出
            </a-button>
          </div>
        </div>
      </div>
      <div class="activity-table">
        <a-table
            :rowKey="record=>record.id"
            :pagination="false"
            :columns="columns"
            :data-source="data">
          <span slot="location" slot-scope="text, record">
            <span>{{returnTitle(record.location)}}</span>
          </span>
        </a-table>
        <!--分页-->
        <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        showQuickJumper
                        showSizeChanger
                        @showSizeChange="showSizeChange"
                        @change="pageChange" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {DrugVisitRecordList, DrugVisitRecordExport} from "@/service/yun_miniProgram_clickRecord";

import codeList from "@/assets/configurationFiles/EduDrugCodeList.json";
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: "6%"
  },{
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    align: 'center',
    ellipsis: true,
    width:'15%'
  },
  {
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    align: 'center',
    width:'8%'
  },
  {
    title: '职称',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    align: 'center',
    ellipsis: true,
    width:'8%'
  },
  {
    title: '点击药品',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    ellipsis: true,
    width:'15%'
  },{
    title: '点击位置',
    dataIndex: 'location',
    key: 'location',
    align: 'center',
    ellipsis: true,
    width:'13%',
    scopedSlots: { customRender: 'location' }
  },
  {
    title: '操作时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    align: 'center',
    width: '13%'
  },
];

const data = [];
export default {
  name:'',
  data(){
    return{
      spinning:false,
      data,
      columns,
      pageSizeOptions: ['10', '20', '30', '40'],
      pagination:{
        total:0,
        pageSize: 10,
        current:1,
      },
      startTime:null,
      endTime:null,
      
    }
  },
  created() {
    this.getList();
  },
  methods:{
    returnTitle(code) {
      let list = codeList;
      for (let i=0;i<list.length;i++){
        if(code === list[i].code){
          return list[i].title;
        }
      }
    },
    //获取接口列表
    async getList() {
      this.spinning = true;
      let data = {
        curPage: this.pagination.current,
        pageSize: this.pagination.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
      }
      const response = await DrugVisitRecordList(data)
      if (response.code === 0) {
        this.data = response.data;
        this.pagination.total = response.count;
      }
      this.spinning = false;
    },
    searchBtn(){
      if(this.startTime && this.endTime){
        this.pagination.current = 1;
        this.pagination.pageSize = 10;
        this.getList();
      }else {
        this.$message.warning("请先选择时间~")
      }

    },
    async deriveBtn() {
      const data = {
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: "药品查询内容浏览记录"
      }
      await DrugVisitRecordExport(data);
    },
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },
    showSizeChange(current, pageSize) {
      // console.log(pageSize)
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    pageChange(pageNum,pageSize){
      this.pagination.current = pageNum;
      this.pagination.pageSize = pageSize;
      this.getList();
    },


  }
}
</script>

<style scoped lang="scss">
.DrugCont-top{
  align-items: center;
  height: 100px;
  border-bottom: 15px solid #f0f2f5;
  .top_cont{
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .DrugCont-top-input{
    height: 50px;
    line-height: 30px;
    padding-top: 30px;
  }
}
.activity-table{
  width: 98%;
  margin: auto;
  padding-top: 30px;
}
.pageView{
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pageLeft{
  font-size: 15px;color: #929292
}
</style>
