import {get, put, del, post, getDrugExel} from "@/service/base";

/**
 *
 * 云 ICU -进修招聘，药品查询 内容点击记录
 */

/**
 * 进修招聘内容浏览记录
 * @param data
 * @returns {Promise<* | void>}
 */
export function educationRecruitmentList(data){
    return get('/v3/educationRecruit/clickRecord',data)
}

/**
 * 导出内容浏览记录
 * @param data
 * @returns {Promise<unknown>}
 */
export function visitRecordExport(data){
    return getDrugExel('/v3/educationRecruit/visitRecord/export',data)
}

/**
 * 获取内容访问记录
 * @param data
 * @returns {Promise<* | void>}
 */
export function DrugVisitRecordList(data){
    return get('/v3/drug/visitRecord',data)
}

/**
 * 导出药品内容访问记录
 * @param data
 * @returns {Promise<unknown>}
 */
export function DrugVisitRecordExport(data){
    return getDrugExel('/v3/drug/visitRecord/export',data)
}
